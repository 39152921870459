import { CompanyUser } from '@nexploretechnology/concreting-core-client';
import { ColumnsType } from 'antd/lib/table';

export const companyUsersColumns: () => ColumnsType<CompanyUser>  = () => {

    return [
        {
            title: 'User Id',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        },

        {
            title: 'User name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },

        {
            title: 'User email',
            dataIndex: 'userEmail',
            key: 'userEmail',
            sorter: true,
        },

        {
            title: 'Company roles',
            dataIndex: 'companyRoles',
            key: 'companyRoles',
            render: (companyRoles: string[]) => <>{`${companyRoles.join(', ')}`}</>
        },

    ];
};

